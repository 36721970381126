<template>
  <div class="form-wrapper">
    <div class="action-button-wrapper">
      <button v-if="disabled" class="btn btn-primary editButton" @click="startEdit">Edit</button>
      <button v-if="!disabled" class="btn btn-primary saveButton" @click="save">Save</button>
    </div>

    <h2 class="card-title">Employee information</h2>
    <div class="card related-user-card">
      <el-form class="related-user-form" ref="related-user-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Employee Name">
            <el-autocomplete
            class="inline-input addMemberInput"
            :fetch-suggestions="searchEmployee"
            v-model="form.employeeId"
            placeholder="Input Employee Name"
            :trigger-on-focus="false"
            ></el-autocomplete>
          </el-form-item>
        </div>
      </el-form>
    </div>

    <h2 class="card-title">Pay Item information</h2>
    <div class="card info-card">
      <el-form ref="payroll-form" :disabled="disabled" :model="form" label-width="200px">
        <div class="row">
          <el-form-item label="Pay Item Name">
            <el-input v-model="form.payItem"></el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Amount" style="max-width: 550px;">
            <el-input type="number" v-model="form.amount">
              <template slot="prepend">$</template>
            </el-input>
          </el-form-item>
        </div>
        <div class="row">
          <el-form-item label="Reason" style="flex-basis: 59%;">
            <el-input
            type="textarea"
            :rows="3"
            v-model="form.reason">
          </el-input>
        </el-form-item>
      </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Common from '@/lib/common';
import Hr from '@/lib/hr';

export default {
  name: 'AdditionPayrollForm',
  props:{
    viewMode: Boolean,
    eventName: String,
  },
  data(){
    let disabled = false;

    //Default form value
    let form = {
      employeeId: '',
      payItem: '',
      amount: 0,
      reason: '',
    };

    //If view mode is enabled, disable the form
    if(this.viewMode === true){
      disabled = true;
    };

    return {
      disabled,
      form,
    };
  },
  methods:{
    startEdit(){
      this.disabled = false;
    },
    save(){
      this.$emit(this.eventName, this.form);
      this.disabled = true;
    },
    async searchEmployee(queryString, cb){
      const loginInfo = Common.getLoginInfo();
      const employees = await Hr.loadAllEmployees(this.apiUrl, loginInfo);
      const employeeSuggest = [];

      for(let i = 0; i < employees.length; i++){
        const employee = employees[i];
        const employeeSelection = {
          'value': employee.employee_name,
          'name': employee.employee_name
        };
        employeeSuggest.push(employeeSelection);
      }
      const results = queryString ? employeeSuggest.filter(this.createEmployeeFilter(queryString)) : employees;

      cb(results);
    },
    createEmployeeFilter(queryString) {
      return (employee) => {
        return (employee.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
  },
  computed: mapState({
    currentAdditionPayroll: state => state.financial.currentAdditionPayroll,
    apiUrl: state => state.apiUrl,
  }),
  watch: {
    'currentAdditionPayroll': function(){
      this.form = {
        employeeId: this.currentAdditionPayroll.employee_name,
        payItem: this.currentAdditionPayroll.pay_item,
        amount: this.currentAdditionPayroll.amount,
        reason: this.currentAdditionPayroll.reason,
      };
    }
  }
}
</script>

<style lang="scss">
.related-user-card{
  padding: 30px;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  .related-user-form{
    margin-left: 15px;
    margin-top: 20px;
  }
}

.info-card{
  padding: 30px;
  margin-bottom: 30px;
}

.card-title{
  margin-bottom: 30px;
}

.contact-info{
  margin-top: 30px;
  padding-top: 30px;
  padding-bottom: 30px;

  .info-card{
    padding-left: 30px;
  }
}

.action-button-wrapper{
  display: flex;
  justify-content: flex-end;
  margin-bottom: 50px;

  .btn{
    margin-right: 20px;
    &:last-child{
      margin: 0;
    }
  }
}
</style>
